<template>
	<edit-template class="add-role" @confirm="onSubmit" @cancel="$router.back()" :cancelRemind="false" confirmText="立即创建" cancelText="取消">

  <div class="form-body">
    <el-form ref="form" :model="form" label-width="120rem">
      <el-form-item label="角色名称" prop="name" :rules="[{ type: 'string', max: 20, message: '角色名称最多不能超过20个字符' },{required:true,message:'请输入角色名称'}]">
        <el-input v-model="form.name" placeholder="请输入角色名称"></el-input>
      </el-form-item>
      <el-form-item label="角色类型" prop="level" :rules="[{required: true,message:'请选择角色类型'}]">
        <el-select :popper-append-to-body="false" v-model="form.level" @change="changeRoleLevel" placeholder="请选择角色类型">
          <el-option v-for="item in roleLevel" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="校区是否可见" :rules="[{required: true,message:'请选择校区是否可见'}]">
        <el-switch v-model="form.role_range"></el-switch>
      </el-form-item>
      <el-form-item label="菜单权限" :rules="[{required:true}]">
        <el-button @click="openSelectMenu">点击选择</el-button>
        <p class="t-color-info" v-if="menuSelectedCount.count">
          已选中 {{menuSelectedCount.count}} 项
        </p>
      </el-form-item>
    </el-form>

  </div>
  <!--    菜单-->
  <permission title="菜单权限" :visible.sync="menuDialog.visible" :config="menuConfig" @confirm="onMenuConfirm" @cancel="onMenuCancel" v-model="permissionIndex">
    <template slot-scope="{data:subMenu,index:i}">
      <div><el-checkbox @change="changeTableSelectionAll($event,subMenu.child)" v-model="subMenu.selected">全选</el-checkbox></div>
      <el-table :data="subMenu.child" :show-header="false" style="height: 180rem;overflow-y: auto;">
        <!--          全选单行   -->
        <el-table-column width="55">
          <template slot-scope="{row,$index}">
            <el-checkbox @change="changeTableSelection($event,row,subMenu,$index)" v-model="row.selectedAll"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="{row,$index}">
            <el-checkbox v-model="row.selected" @change="changeTableSelectionItem($event, row,subMenu,1)">{{ row.name }}</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column v-for="(item,i) in calcMaxColumn(subMenu.child)">
          <template slot-scope="{row,$index}">
            <el-checkbox v-if="row.button_options[i]" :label="row.button_options[i].type" v-model="row.button_options[i].selected" :disabled="!row.selected" @change="changeTableSelectionItem($event,row,subMenu)">{{row.button_options[i].button}}</el-checkbox>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </permission>

</edit-template>
</template>

<script>
import permission from "./components/permission"

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data(){
    return{
      form:{
        name:null,
        level:null,
        role_range:false
      },
      permissionIndex:'0',
      roleLevel:[],
      // 菜单权限选项展示
      menuDialog: {
        visible:false,
        firstMenu:"m_1_0",
        secondMenu:"m_2_0"
      },
      areaVisible:false,
      menuConfig:[],
      menuConfigBackup:[],
      submitLoading:false
    }
  },
  mounted() {
    this.init();
  },
  computed:{
    menuSelectedCount(){
      let menuConfig = JSON.parse(JSON.stringify(this.menuConfig));
      return this.formatData(menuConfig,"menu");
    },
  },
  methods:{
    log:console.log,
    formatData(data,type="other"){
      let result = {};
      switch (type){
        case "menu":
          result.menu = new Set();
          result.buttons = [];
          result.count = 0;
          let getParentId = (item)=>{
            result.menu.add(item.parent_id);
            if (item.parent)getParentId(item.parent);
          }
          // 重组校区和按钮字段
          this.$tools.eachCheckboxTree(data,{child:"child",isFirst:true},(item)=>{
            // 获取选中
            if (item.selected){
              getParentId(item);
              result.menu.add(item.id);
              if (item.tier === 2) {
                result.count++;
              }
            }
            // 获取button信息
            if (item.button_options && item.button_options.length){
              item.button_options.forEach(btn=>{
                if (!btn.selected) return;
                let {type,button,url} = btn;
                result.buttons.push({
                  button,
                  type,
                  url,
                  id:item.id
                })
              })
            }
          })
          // 删掉 0  0是默认值 无用
          result.menu.delete(0);
          result.total = result.menu.size + result.buttons.length;

          break;
        default:
          result.data=[];
          data.forEach(item=>{
            if (item.selected)result.data.push(item.id)
          })
          result.total = result.data.length;
          break;
      }
      return result;
    },
    onSubmit(){
      let {name,level,role_range} = this.form;
      if (this.$tools.isEmpty(name)){
        return this.$message.warning("请设置角色名称")
      }else if(this.$tools.isEmpty(level)){
        return this.$message.warning("请选择角色类型")
      }
      // 菜单选中结果
      let menus = new Set(),
      // 按钮选中结果
          buttons = [],
          // 校区选中结果
          school = [],
          // 年级选中结果
          grade = [],
          // 科目选中结果
          subject = [];
      let menuConfig = JSON.parse(JSON.stringify(this.menuConfig));
      let res = this.formatData(menuConfig,"menu")
      menus = res.menu;
      buttons = res.buttons;
      if(!menus.size){
        return this.$message.warning("请选择菜单权限")
      }

      this.$_axios2.post("/api/permission/add-role",{
        ...this.form,
        role_data:buttons,
        permit_menu:Array.from(menus),
        role_range:role_range?1:0,
        // school_ids:school,
        // grade_ids:grade,
        // subject_ids:subject
      },{logic:1}).then(res=>{
        this.$store.commit("setPage",1);
        this.$router.back()
      })
    },
    setSelectedAttr(data){
      return data.map(item=>{
        item.selected = false;
        return item
      })
    },
    // 初始化
    async init(){
      let loading = this.$loading({text:"请稍后..."});
      // 获取角色类型列表
      await this.$utils.roleApi.getRoleLevel().then(res => {
        let { data } = res.data
        this.roleLevel = data.map((item) => ({
          label: item.name,
          value: item.id
        }))
      })
      loading.close();
    },
    openSelectMenu(){
      if (this.form.level == null)return this.$message.warning("请选择角色类型")
      this.menuDialog.visible = true;
      this.menuConfigBackup = JSON.parse(JSON.stringify(this.menuConfig));

    },
    /** 当角色类型被更改*/
    changeRoleLevel(){
      // 获取菜单配置
      let loading = this.$loading({
        text:"请稍后"
      })
      this.permissionIndex='0';
      // 获取菜单权限
      this.$utils.roleApi.getMenuConfigList1(this.form.level).then(res => {
        let { data } = res
        let item = this.$tools.eachCheckboxTree(data, {
          child: 'child',
          isFirst: true,
          selected: false,
          parent:false
        },(e)=>{
          if (e.level === 3){
            e.button_options.forEach(item=>{
              item.selected = false;
            })
            e.selectedAll = false;
          }
        })
        this.menuConfig = Object.assign(item);
      }).finally(()=>loading.close())
    },
    /**
     * 计算最大列数(在菜单权限 - 按钮权限中 , 由于每个子菜单的按钮权限不同 ,为了列布局, 所以需要取最大值)
     * @param child 子菜单
     * @return {number} 最大值
     */
    calcMaxColumn(child){
      let max = 0;
      if (!child)return 0;
      child.forEach(item=>{
        max = Math.max(item.button_options.length,max);
      })
      return max;
    },
    /**
     * 计算指定数据是否被全部选中
     * @param data 一个数组
     * @param key 选中标记项的属性名
     * @return {boolean}
     */
    calcDataIsSelectedAll(data,key="selected"){
      for (let item of data){
        if (!item[key]) {
          return false;
        }
      }
      return true;
    },
    // 检测数据中的数据是否被全部选中
    checkSelectedAll(data,parent){
      parent.selected=this.calcDataIsSelectedAll(parent.child, "selectedAll");
    },
    // 更改表格选项
    changeTableSelectionItem(e,data,parent,main=0){
      if (main && !e){
        data.button_options.forEach(item=>{
          item.selected = false;
        })
        data.selectedAll = false;
        parent.selected = false;
        return;
      }
      let res = this.calcDataIsSelectedAll(data.button_options);
      let val = res && data.selected;
      data.selectedAll = val;
      if(!val)parent.selected = false;
      else {
        this.checkSelectedAll(data,parent)
      }
    },
    // 当表格行被选中
    changeTableSelection(e,data,parent){
      data.selected = e;
      data.button_options.forEach(item=>{
        item.selected = e;
      })
      this.checkSelectedAll(data,parent)

    },
    // 当表格全选被选中
    changeTableSelectionAll(e,data){
      data.forEach(item=>{
        item.selected = e;
        item.selectedAll = e;
        item.button_options.forEach(item=>{
          item.selected = e;
        })
      })
    },
    onMenuConfirm(){
      this.menuDialog.visible = false;
    },
    onMenuCancel(){
      this.menuConfig = JSON.parse(JSON.stringify(this.menuConfigBackup));
    },
  },
  components:{
    permission
  }
}
</script>

<style lang="scss" scoped>
.add-role{
  position: relative;
  padding: 20rem;
  min-height: calc(100% - 20rem);
  ::v-deep .el-dialog{
    width: 70% !important;
    min-width: calc(1140rem * 0.8);
  }
  .tab-item{
    max-height:40vh;
    overflow-y: auto;
  }
  .footer-button{
    position: absolute;
    bottom: 0;
  }

}
</style>
